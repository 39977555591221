<style lang="scss">
  .google-btn{
    border: 1px solid #DFE3E8;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
    padding:14px 20px 0 20px;
    height: 48px;
    text-align:right;
    color: #757575;
    font-size: 14px;
    letter-spacing: 0.46px;
    line-height:1.2rem;
    cursor:pointer;

    @media (min-width: 360px) {
      font-size: 16px;
      text-align:center;
    }
  }

  .or{
    position: relative;
    font-size: 14px;
    color:#4A4A4A;
    z-index: 1;
    overflow: hidden;
    padding:20px 10px;
    text-align: center;
  }
  .or:before, .or:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: #EDEDED;
  }
  .or:before {
    margin-left: -50%;
    text-align: right;
  }
</style>
<template>
  <div
    class="py-16 bg-grey-light min-h-full"
  >
    <v-inner-header
      :hide-close="true"
    />
    <vue-headful
      title="Login | Octoa"
    />
    <div
      v-if="isAuth"
      class="pt-24 text-center my-32"
    >
      <img
        src="@/assets/img/icons/loader.svg"
        width="45"
      >
    </div>
    <div
      v-if="!isAuth"
      class="w-full px-4"
    >
      <div class="max-w-xs text-center mx-auto">
        <h1 class="mt-16 mb-8 text-2xl lg:text-3xl">
          Login
        </h1>
      </div>
      <form
        class="max-w-sm mx-auto bg-white rounded mt-8 px-8 pt-6 pb-8 mb-4"
        @submit.prevent="login"
      >
        <div class="mb-4">
          <label
            class="block text-grey-darker text-sm mb-2"
            for="username"
          >
            Email address
          </label>
          <input
            id="email"
            ref="email"
            v-model="form.email"
            class="w-full"
            type="text"
            placeholder="Email address"
          >
        </div>
        <div>
          <label
            class="block text-grey-darker text-sm mb-2"
            for="password"
          >
            Password
          </label>
          <input
            id="password"
            v-model="form.password"
            class="w-full"
            type="password"
            placeholder="******************"
          >
        </div>
        <div class="flex my-5 items-center justify-between">
          <v-checkbox
            id="rememberMe"
            v-model="form.rememberMe"
            classes="small"
            label="Remember Me"
          />
          <router-link
            to="/forget-password"
            class="inline-block align-baseline text-sm text-blue hover:text-blue-darker"
          >
            Forgot Password?
          </router-link>
        </div>

        <button
          v-if="!loading"
          class="green-btn w-full rounded"
          data-cy="submit"
          type="submit"
        >
          Log in
        </button>
        <div
          v-else
          class="text-center py-1"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="38"
          >
        </div>
        <div class="or">
          &nbsp;&nbsp;or&nbsp;&nbsp;
        </div>
        <div
          class="google-btn"
          @click="loginWithGoogle"
        >
          <div class="absolute pin-l ml-4">
            <img
              src="@/assets/img/icons/ico-google.svg"
              width="20"
            >
          </div>
          Sign in with Google
        </div>
      </form>
      <div class="mt-5 max-w-md text-center mx-auto">
        <router-link
          to="/register"
          class="inline-block align-baseline text-sm text-grey-darker hover:text-blue-darker"
        >
          Don't have an account? Register for a free 14 days trial.
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import storage from '@/storage'

export default {
  name: 'Login',
  data(){
    return {
      error: false,
      form: {
        type: 'account',
        email: '',
        password: '',
        googleId: '',
        rememberMe: true,
      },
      loading: false,
      isAuth: auth.check(),
      user: auth.user(),
    }
  },
  mounted() {
    if(this.isAuth){
      setTimeout(() => this.$router.replace({ name: 'Dashboard' }), 0)
    }

    if(!this.isAuth){
      this.$refs.email.focus()
    }

    if(this.$route.query.refcode){
      storage.set('refcode', this.$route.query.refcode)
    }
  },
  methods: {
    loginWithGoogle() {
      this.$gAuth
      .signIn()
      .then(GoogleUser => {
        this.form.type = 'google'
        this.form.googleId = GoogleUser.getId()
        this.form.email = GoogleUser.getBasicProfile().getEmail()
        // console.log('Response:', GoogleUser.getId())
        //console.log('getBasicProfile', GoogleUser.getBasicProfile())
        //console.log('getAuthResponse', GoogleUser.getAuthResponse())
        this.login()
      })
      .catch(error => {
        this.$toasted.global.general_error({
          message : 'Oops, there\'s an error connecting to your Google account.'
        })
      })
    },
    async login() {
      this.loading = true
      this.error = false
      this.$toasted.clear()

      try {
        await this.$store.dispatch('auth/login', this.form)

        if(process.env.NODE_ENV === 'production'){
          window.Intercom('boot', {
             app_id: 'ibkb0noy',
             email: auth.user().me.email,
             name: `${auth.user().me.first_name} ${auth.user().me.last_name}`,
             user_id: auth.user().me.id,
             created_at: new Date(auth.user().me.created_at).getTime(),
          })
        }

        if(!auth.user().me.company){
          setTimeout(() => this.$router.replace({ name: 'RegisterCompany' }), 0)
        } else {

          this.$gtag.set({
            'userId': `${auth.user().me.company.id}`,
          })

          this.redirect()
        }
      } catch (e) {
        this.error = true
        //this.$toasted.global.api_error(e)
      }

      if(auth.user()){
        this.redirect()
      } else if(this.error){
        this.$toasted.global.api_error({ message: 'The credentials are invalid.'})
      }

      this.loading = false
    },
    redirect(){
      if(this.$route.query.redirect && this.$route.query.redirect != '/' ){
        setTimeout(() => this.$router.replace({ path: this.$route.query.redirect }), 0)
      } else {
        setTimeout(() => this.$router.replace({ name: 'Dashboard' }), 0)
      }
    },
    async logout() {
      this.$toasted.clear()

      try {
        this.$store.dispatch('auth/logout')
      } catch(e) {
        this.$toasted.global.api_error(e)
      }

      window.location.reload(true)
    }
  },
}
</script>
